<script>
import appConfig from "../../../app.config";
import SwiperCore, {
    Thumbs,
    Navigation,
    Pagination
} from "swiper";
import {
    Swiper,
    SwiperSlide
} from "swiper/vue";
import "swiper/swiper-bundle.css";
import {
    CountTo
} from "vue3-count-to";

import { mapGetters, mapState } from 'vuex';

SwiperCore.use([Thumbs, Navigation, Pagination]);
export default {
    page: {
        title: "Starter",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Starter",
            items: [{
                text: "Pages",
                href: "/",
            },
            {
                text: "Starter",
                active: true,
            },
            ],
        };
    },
    components: {
        Swiper,
        SwiperSlide,
        CountTo,
    },
    computed: {
        ...mapGetters({
            getProfileAttachments: "profile/getProfileAttachments",
        }),
        ...mapState('auth', {
            userData: state => state.user,
        }),
        profileType() {
            if (this.userData.role_id == 1) {
                return 'Super Admin'
            } else if (this.userData.role_id == 2) {
                return 'Team'
            } else if (this.userData.role_id == 3) {
                return 'User'
            }
            return null;
        }
    },
    methods: {
        monthly() {
            const highlightedItems = document.querySelectorAll(".month");

            highlightedItems.forEach(function (userItem) {
                userItem.style.display = "block";
            });
            const highlightedItems2 = document.querySelectorAll(".annual");

            highlightedItems2.forEach(function (userItem) {
                userItem.style.display = "none";
            });
        },
        anually() {
            const highlightedItems = document.querySelectorAll(".month");

            highlightedItems.forEach(function (userItem) {
                userItem.style.display = "none";
            });
            const highlightedItems2 = document.querySelectorAll(".annual");

            highlightedItems2.forEach(function (userItem) {
                userItem.style.display = "block";
            });
        },
    },
    mounted() {
        this.monthly();
        var planSwitch = document.getElementById("plan-switch")
        if (planSwitch) {
            planSwitch.addEventListener('change', () => {
                if (planSwitch.checked == true) {
                    this.anually();
                } else if (planSwitch.checked == false) {
                    this.monthly();
                }
            });
        }

        window.addEventListener('scroll', function (ev) {
            ev.preventDefault();
            var navbar = document.getElementById("navbar");
            if (navbar) {
                if (document.body.scrollTop >= 50 || document.do1cumentElement.scrollTop >= 50) {
                    navbar.classList.add("is-sticky");
                } else {
                    navbar.classList.remove("is-sticky");
                }
            }
        });

        document.querySelector('.currentyear').innerHTML = new Date().getFullYear() + " © Velzon - Themesbrand";
    },
};
</script>

<template>
    <div class="layout-wrapper landing" id="home">
        <nav class="navbar navbar-expand-lg navbar-landing fixed-top" id="navbar" style="background-color:#f9fafc">
            <div class="container">
                <b-link class="navbar-brand" href="/">
                    <img src="@/assets/images/triq-logo.png" class="card-logo card-logo-dark" alt="logo dark" height="45">
                    <img src="@/assets/images/triq-logo.png" class="card-logo card-logo-light" alt="logo light" height="45">
                </b-link>
                <button class="navbar-toggler py-0 fs-20 text-body" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i class="mdi mdi-menu"></i>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                        <li><a href="#home" class="btn btn-link fw-medium text-decoration-none text-dark">Home</a></li>
                        <li><a href="#about" class="btn btn-link fw-medium text-decoration-none text-dark">About</a></li>
                        <li><a href="#faq" class="btn btn-link fw-medium text-decoration-none text-dark">FAQ's</a></li>
                        <li><a href="#calendar" class="btn btn-link fw-medium text-decoration-none text-dark">Due Date
                                Calendar</a></li>
                        <li><a href="#footer" class="btn btn-link fw-medium text-decoration-none text-dark">Download App</a>
                        </li>

                        <li><button type="button" class="btn " data-bs-toggle="modal"
                                data-bs-target="#myModal">Contact</button></li>

                    </ul>


                    <div class="" v-if="userData">
                        <a href="/orders" class="btn btn-link fw-medium text-decoration-none text-dark">Dashboard</a>
                    </div>

                    <div v-else>
                        <router-link to="/login"
                            class="btn btn-link fw-medium text-decoration-none text-dark">Login</router-link>
                        <router-link to="/register" class="btn btn-primary me-3">Register</router-link>
                        <!-- <router-link to="/partner-register" class="btn btn-primary">Partner Register</router-link> -->

                        <span class="dropdown">
                            <button class="btn btn-primary" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Partner Register
                            </button>
                            <ul class="dropdown-menu mt-3" aria-labelledby="dropdownMenuButton1">
                                <li><router-link class="dropdown-item" to="/partner-register?type=consultant">Consultant</router-link></li>
                                <li><router-link class="dropdown-item" to="/partner-register?type=accountant">Qualified Accountant</router-link></li>
                            </ul>
                        </span>

                    </div>


                </div>

            </div>
        </nav>
        <!-- end navbar -->

        <!-- start hero section -->

        <section class="section pb-0 hero-section" id="hero">
            <div class="bg-overlay bg-overlay-pattern"></div>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="">
                        <div class="mt-4  mb-sm-n5 demo-carousel">
                            <div class="demo-img-patten-top d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>
                            <div class="demo-img-patten-bottom d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>
                            <div class="carousel slide carousel-fade" data-bs-ride="carousel">
                                <div class="carousel-inner  p-2 rounded">

                                    <div class="carousel-item active" data-bs-interval="2000">
                                        <img src="@/assets/images/banner1.png" class="d-block w-100" alt="...">
                                    </div>

                                    <div class="carousel-item" data-bs-interval="2000">
                                        <img src="@/assets/images/banner2.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item" data-bs-interval="2000">
                                        <img src="@/assets/images/banner3.png" class="d-block w-100" alt="...">
                                    </div>
                                    <div class="carousel-item" data-bs-interval="2000">
                                        <img src="@/assets/images/banner4.png" class="d-block w-100" alt="...">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- slider ends -->
                </div>
                <!-- end container -->
                <div class="container">
                    <div class="col-md-12 pb-5">
                        <div class="mt-lg-5 pt-5">
                            <h1 class="display-6 fw-bold mb-3 lh-base">Streamline Your business & tax compliances with our
                                services in
                                <span class="text-primary"> India and UAE </span>
                            </h1>
                            <p class="lead lh-base">E-Triq Tax Ventures Private Limited is a company incorporated in 2022.
                                The company provides online platform for tax filings and other services like incorporation
                                of a company etc. </p>

                            <ul>
                                <li>Income Tax</li>
                                <li>GST</li>
                                <li>India/UAE Company Registration</li>
                                <li>TDS Filing</li>
                                <li>UAE/VAT</li>
                                <li>& more...</li>
                            </ul>

                            <div class="d-flex gap-2  mt-4">
                                <b-link href="/login" class="btn btn-primary">Login Now <i
                                        class="ri-arrow-right-line align-middle ms-1"></i></b-link>
                                <!-- <router-link to="/pages/pricing" class="btn btn-danger">View Plans <i
                                        class="ri-eye-line align-middle ms-1"></i></router-link> -->
                            </div>
                        </div>


                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
            <div class="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
                        <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z">
                        </path>
                    </g>
                </svg>
            </div>
            <!-- end shape -->
        </section>
        <!-- end hero section -->





        <!-- start client section -->
        <div class="mt-1">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-9">

                        <div class="text-center mt-5">
                            <h5 class="fs-20">Powered <span class="text-primary text-decoration-underline">by</span> the
                                world's best Technology</h5>
                            <div class="trusted-client-slider mt-sm-5 mt-4 mb-sm-5 mb-4">
                                <swiper class="swiper responsive-swiper rounded gallery-light pb-4" :loop="true"
                                    :slidesPerView="1" :spaceBetween="10" :pagination="{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                    }" :breakpoints="{
    640: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    768: {
        slidesPerView: 3,
        spaceBetween: 40,
    },
    1200: {
        slidesPerView: 3,
        spaceBetween: 50,
    },
}">
                                    <swiper-slide>
                                        <div class="swiper-slide">
                                            <div class="client-images">
                                                <img src="@/assets/images/1.png" alt="client-img"
                                                    class="mx-auto img-fluid d-block">
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <!-- end slide item -->

                                    <swiper-slide>
                                        <div class="swiper-slide">
                                            <div class="client-images">
                                                <img src="@/assets/images/2.png" alt="client-img"
                                                    class="mx-auto img-fluid d-block">
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide>
                                        <div class="swiper-slide">
                                            <div class="client-images">
                                                <img src="@/assets/images/3.png" alt="client-img"
                                                    class="mx-auto img-fluid d-block">
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide>
                                        <div class="swiper-slide">
                                            <div class="client-images">
                                                <img src="@/assets/images/2.png" alt="client-img"
                                                    class="mx-auto img-fluid d-block">
                                            </div>
                                        </div>
                                    </swiper-slide>

                                    <!-- end slide item -->
                                </swiper>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end client section -->

        <!-- start services -->
        <section class="section" id="about" style="background-color: #f9fafc;">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="text-center mb-5">
                            <h1 class="mb-3 ff-secondary fw-bold lh-base">Tax filing has never been so easy and quick. Tax
                                return is quick with E-TRIQ</h1>
                            <p class="">E-Triq Tax Ventures Private Limited is a company incorporated in 2022. The company
                                provides online platform for tax filings and other services like incorporation of a company
                                etc. The platform helps users to book services for consultation, online filing of tax
                                returns, registration applications, incorporation applications etc. The services are
                                delivered by young trained professionals and monitored by professionals having vast
                                experience in the field of company laws and laws of taxation. The company’s vision is to
                                bring easy compliance and professional support available to all the business and
                                individuals.
                            </p>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->


            </div>
            <!-- end container -->
        </section>
        <!-- end services -->

        <!-- start features -->


        <!-- start faqs -->
        <section class="section" id="faq">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="text-center mb-5">
                            <h3 class="mb-3 fw-semibold">Frequently Asked Questions</h3>
                            <p class="text-muted mb-4 ff-secondary">If you can not find answer to your question in our
                                FAQ, you can
                                always contact us or email us. We will answer you shortly!</p>


                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row g-lg-5 g-4">
                    <div class="col-lg-6">
                        <!-- <div class="d-flex align-items-center mb-2">
                            <div class="flex-shrink-0 me-1">
                                <i class="ri-question-line fs-24 align-middle text-success me-1"></i>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="mb-0 fw-semibold">General Questions</h5>
                            </div>
                        </div> -->
                        <div class="accordion custom-accordionwithicon custom-accordion-border accordion-border-box"
                            id="genques-accordion">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="genques-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#genques-collapseOne" aria-expanded="true"
                                        aria-controls="genques-collapseOne">
                                        What is E-Triq
                                    </button>
                                </h2>
                                <div id="genques-collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="genques-headingOne" data-bs-parent="#genques-accordion">
                                    <div class="accordion-body ff-secondary">
                                        E-Triq is a web & mobile based application which provides online consultation, helps
                                        users to incorporate a company, supports filing of various tax returns under the
                                        guidance of Experts in India and UAE.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="genques-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#genques-collapseTwo" aria-expanded="false"
                                        aria-controls="genques-collapseTwo">
                                        How does it work
                                    </button>
                                </h2>
                                <div id="genques-collapseTwo" class="accordion-collapse collapse"
                                    aria-labelledby="genques-headingTwo" data-bs-parent="#genques-accordion">
                                    <div class="accordion-body ff-secondary">
                                        E-Triq operates like an E-commerce Platform which provides you personalized Services
                                        through phone call support , email communication and Option to chat with an expert.
                                        Each and every conversation is kept confidential and recorded.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="genques-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#genques-collapseThree" aria-expanded="false"
                                        aria-controls="genques-collapseThree">
                                        If I had opted for one service, can I upgrade to another one
                                    </button>
                                </h2>
                                <div id="genques-collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="genques-headingThree" data-bs-parent="#genques-accordion">
                                    <div class="accordion-body ff-secondary">
                                        Yes, you may upgrade the package anytime before completion of the service.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="genques-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#genques-collapseFour" aria-expanded="false"
                                        aria-controls="genques-collapseFour">
                                        Do you support in tax payments
                                    </button>
                                </h2>
                                <div id="genques-collapseFour" class="accordion-collapse collapse"
                                    aria-labelledby="genques-headingFour" data-bs-parent="#genques-accordion">
                                    <div class="accordion-body ff-secondary">
                                        Yes, you may write to us in chat option which gets opened after booking any service.
                                        Our experts will guide you to pay your taxes.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="genques-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#genques-collapseSix" aria-expanded="false"
                                        aria-controls="genques-collapseSix">
                                        Do you support in tax planning
                                    </button>
                                </h2>
                                <div id="genques-collapseSix" class="accordion-collapse collapse"
                                    aria-labelledby="genques-headingFour" data-bs-parent="#genques-accordion">
                                    <div class="accordion-body ff-secondary">
                                        Yes, you may avail the facility of online consultation and talk to an expert.
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!--end accordion-->

                    </div>
                    <!-- end col -->
                    <div class="col-lg-6">
                        <!-- <div class="d-flex align-items-center mb-2">
                            <div class="flex-shrink-0 me-1">
                                <i class="ri-shield-keyhole-line fs-24 align-middle text-success me-1"></i>
                            </div>
                            <div class="flex-grow-1">
                                
                            </div>
                        </div> -->

                        <div class="accordion custom-accordionwithicon custom-accordion-border accordion-border-box"
                            id="privacy-accordion">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="privacy-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#privacy-collapseOne" aria-expanded="false"
                                        aria-controls="privacy-collapseOne">
                                        Do you have any specific format in which data is required to be shared
                                    </button>
                                </h2>
                                <div id="privacy-collapseOne" class="accordion-collapse collapse"
                                    aria-labelledby="privacy-headingOne" data-bs-parent="#privacy-accordion">
                                    <div class="accordion-body ff-secondary">
                                        No, you may share data in .pdf, .jpg, .jpeg, excel, word or any other readable
                                        format.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="privacy-headingTwo">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#privacy-collapseTwo" aria-expanded="false"
                                        aria-controls="privacy-collapseTwo">
                                        Can I share data over Google Drive
                                    </button>
                                </h2>
                                <div id="privacy-collapseTwo" class="accordion-collapse collapse show"
                                    aria-labelledby="privacy-headingTwo" data-bs-parent="#privacy-accordion">
                                    <div class="accordion-body ff-secondary">
                                        Yes you can upload the data on google drive and can share the link of the same on
                                        the application under chat option under each service.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="privacy-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#privacy-collapseThree" aria-expanded="true"
                                        aria-controls="privacy-collapseThree">
                                        I want to start a new business. How does E- Triq help me
                                    </button>
                                </h2>
                                <div id="privacy-collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="privacy-headingThree" data-bs-parent="#privacy-accordion">
                                    <div class="accordion-body ff-secondary">
                                        E-Triq helps you to plan the structure of your organization like proprietorship,
                                        partnership, private Limited etc. ( LLC , free zone entity, mainland entity in UAE)
                                        as per your requirements, then helps you with the formation of the organization,
                                        obtain necessary registrations and file your returns.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="privacy-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#privacy-collapseFour" aria-expanded="false"
                                        aria-controls="privacy-collapseFour">
                                        Do you offer Phone Support
                                    </button>
                                </h2>
                                <div id="privacy-collapseFour" class="accordion-collapse collapse"
                                    aria-labelledby="privacy-headingFour" data-bs-parent="#privacy-accordion">
                                    <div class="accordion-body ff-secondary">
                                        Yes, our support numbers are mentioned under the ‘contact us’.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="privacy-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#privacy-collapseFive" aria-expanded="false"
                                        aria-controls="privacy-collapseFive">
                                        Why I should choose E-TRIQ
                                    </button>
                                </h2>
                                <div id="privacy-collapseFive" class="accordion-collapse collapse"
                                    aria-labelledby="privacy-headingFour" data-bs-parent="#privacy-accordion">
                                    <div class="accordion-body ff-secondary">
                                        <ul>
                                            <li>No follow ups required</li>
                                            <li>The process flow of each service and timelines is known before opting for
                                                the same
                                            </li>
                                            <li>100% transparency</li>
                                            <li>Chat option on Mobile application makes it very convenient to share the
                                                information and discuss the query.</li>
                                            <li>Data security</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end accordion-->
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </section>
        <!-- end faqs -->
        <section class="section" id="calendar" style="background-color:#f9fafc">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="text-center mb-5">
                        <h3 class="mb-3 fw-semibold">Due Date Calendar</h3>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">

                <div class="col-md-6">
                    <iframe
                        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FKolkata&showTabs=1&showTz=0&showDate=1&showTitle=1&src=NDg0NGMyY2JmYWZkNmNjODI3NDM2OTY1OGFiYzQyNWY3YWVmYTI2OTllZTgxMjhmOWU4MTg0NTU3ZDQ3MmE5NEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%233F51B5"
                        style="border:solid 1px #777" width="100%" height="600" frameborder="0" scrolling="no"></iframe>
                </div>
            </div>
        </section>
        <!-- start review -->
        <section class="section bg-primary" id="reviews">
            <div class="bg-overlay bg-overlay-pattern"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="text-center">
                            <div>
                                <i class="ri-double-quotes-l text-success display-3"></i>
                            </div>
                            <h4 class="text-white mb-5"><span class="text-success">1k</span>+ Satisfied clients</h4>

                            <div class="client-review-swiper rounded">
                                <swiper class="navigation-swiper rounded" :loop="true" :autoplay="{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }" :navigation="{
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
}" :pagination="{
    clickable: true,
    el: '.swiper-pagination',
}">
                                    <swiper-slide>
                                        <div class="swiper-slide">
                                            <div class="row justify-content-center">
                                                <div class="col-10">
                                                    <div class="text-white-50">
                                                        <p class="fs-20 ff-secondary mb-4">" Being an editor, I always look
                                                            for perfection. Initially, I was not sure if E-TRIQ would be
                                                            able to meet my expectations but as they started working on my
                                                            tax filings, my confidence in them strengthened and I knew that
                                                            I am in safe hands
                                                        </p>

                                                        <div>
                                                            <h5 class="text-white">Vivek Shukla
                                                            </h5>
                                                            <p>Managing partner and Editor-in-chief, PSU Watch</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <!-- end slide item -->
                                    <swiper-slide>
                                        <div class="swiper-slide">
                                            <div class="row justify-content-center">
                                                <div class="col-10">
                                                    <div class="text-white-50">
                                                        <p class="fs-20 ff-secondary mb-4">" With so much occupancy in the
                                                            corporate world, personal finances take a back seat at times.
                                                            E-TRIQ acted as a saviour and supported me with my tax filings
                                                            with click of a button and easy chat option. Mobile application
                                                            provides easy access anywhere anytime. The team knows exactly
                                                            what to do and backed by professionally sound people. They also
                                                            went out of turn to guide me in managing my savings for next
                                                            period.</p>

                                                        <div>
                                                            <h5 class="text-white">Mr Harsh Choudhary</h5>
                                                            <p>Director-Finance (M/s AMP India Private Limited)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <!-- end slide item -->
                                    <swiper-slide>
                                        <div class="swiper-slide">
                                            <div class="row justify-content-center">
                                                <div class="col-10">
                                                    <div class="text-white-50">
                                                        <p class="fs-20 ff-secondary mb-4">""I have been with E-Triq for
                                                            over a year now and I am very satisfied with their services.
                                                            They are always on time, they respond well to my emails and they
                                                            do excellent work. I would recommend them to anyone looking for
                                                            an Tax Returns.""</p>

                                                        <div>
                                                            <h5 class="text-white">Tarun Sehgal</h5>
                                                            <p>- Senior Manager, HCL</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <div class="swiper-button-next bg-white rounded-circle"></div>
                                    <div class="swiper-button-prev bg-white rounded-circle"></div>
                                    <div class="swiper-pagination position-relative mt-2"></div>
                                    <!-- end slide item -->
                                </swiper>
                            </div>



                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </section>
        <!-- end review -->

        <!-- start counter -->
        <section class="py-5 position-relative bg-light d-none">
            <div class="container">
                <div class="row text-center gy-4">
                    <div class="col-lg-3 col-6">
                        <div>
                            <h2 class="mb-2">
                                <count-to :startVal='0' :endVal='100' :duration='5000'>0</count-to>+
                            </h2>
                            <div class="text-muted">Projects Completed</div>
                        </div>
                    </div>
                    <!-- end col -->

                    <div class="col-lg-3 col-6">
                        <div>
                            <h2 class="mb-2">
                                <count-to :startVal='0' :endVal='24' :duration='5000'>0</count-to>
                            </h2>
                            <div class="text-muted">Win Awards</div>
                        </div>
                    </div>
                    <!-- end col -->

                    <div class="col-lg-3 col-6">
                        <div>
                            <h2 class="mb-2">
                                <count-to :startVal='0' :endVal='20' :duration='5000'>0</count-to>k
                            </h2>
                            <div class="text-muted">Satisfied Clients</div>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-3 col-6">
                        <div>
                            <h2 class="mb-2">
                                <count-to :startVal='0' :endVal='50' :duration='5000'>0</count-to>
                            </h2>
                            <div class="text-muted">Employees</div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </section>
        <!-- end counter -->




        <!-- Start footer -->
        <footer class="custom-footer  bg-dark py-5 position-relative" id="footer">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-3">
                        <img src="@/assets/images/ios.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="col-lg-3">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.etriqtax"><img
                                src="@/assets/images/android.png" class="d-block w-100" alt="..."></a>
                    </div>

                </div>

                <div class="row text-center text-sm-start justify-content-center mt-4">
                    <div class="col-sm-12">

                        <div class="text-sm-center mt-sm-0">
                            <ul class="list-inline mb-0 footer-social-link">
                                <li class="list-inline-item">
                                    <a href="javascript: void(0);" class="avatar-xs d-block">
                                        <div class="avatar-title rounded-circle">
                                            <i class="ri-facebook-fill"></i>
                                        </div>
                                    </a>
                                </li>

                                <li class="list-inline-item">
                                    <a href="javascript: void(0);" class="avatar-xs d-block">
                                        <div class="avatar-title rounded-circle">
                                            <i class="ri-linkedin-fill"></i>
                                        </div>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="javascript: void(0);" class="avatar-xs d-block">
                                        <div class="avatar-title rounded-circle">
                                            <i class="ri-google-fill"></i>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </div>
                    <!-- col-sm-12 -->

                    <div class="col-sm-12 mt-4">
                        <p class="copy-rights mb-0 currentyear text-center">Copyright @ E-Triq Tax Ventures Private Limited
                        </p>
                        <p class="text-center"><a href="/pages/privacy">Privacy Policy </a> | <a href="/pages/terms">Terms &
                                Conditions</a></p>
                        <p class="text-muted text-center mt-3 text-sm">Developed by <a href="https://thinkncode.com"
                                target="_blank">Think N Code</a></p>
                    </div>

                    <div class="live-preview">
                        <div>

                            <div id="myModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel"
                                aria-hidden="true" style="display: none;">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="myModalLabel">E-Triq Tax Ventures Private Limited
                                        </h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <h5 class="fs-15 mb-4">Get in touch:</h5>
                                        <p class="text-muted"><i class="bx bx-map "></i> &nbsp; B-357-A KH.
                                            No-389/263/1, First Floor, New Ashok Nagar, East Delhi, Delhi, India, 110096
                                        </p>
                                        <p class="text-muted"><i class="bx bx-phone-call "></i> &nbsp; +91-99908-22276
                                        </p>
                                        <p class="text-muted"><i class="bx bx-mail-send "></i> &nbsp; support@e-triq.com
                                        </p>

                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-light"
                                            data-bs-dismiss="modal">Close</button>

                                    </div>
                                </div>
                                <!-- /.modal-content -->
                            </div>
                            <!-- /.modal-dialog -->
                        </div>
                        <!-- /.modal -->
                    </div>
                </div>




            </div>
        </div>
    </footer>
    <!-- end footer -->

</div></template>
<style scoped>.text-primary {
    color: #0d4369 !important;
}

.bg-primary {
    background-color: #0d4369 !important;
}</style>